<template>
  <b-card no-body>
    <b-card-header class="py-1" header-tag="h3">
      <slot name="seriesHeader" v-bind="series"></slot>
      {{series.DisplayName}}
    </b-card-header>
    <b-card-body v-if="series && series.ManufacturingSeries.length > 0" class="p-1 w-auto">
      <model-manufacturingSeries :manufacturingSeries="manufacturingSeries" :fields="fields" v-for="manufacturingSeries in series.ManufacturingSeries" :key="manufacturingSeries.ManufacturingSeriesId" :customerNumber="customerNumber">
        <template #manufacturingSeriesHeader="manufacturingSeries"><slot name="manufacturingSeriesHeader" v-bind="manufacturingSeries"></slot></template>
        <template #productHeader="product"><slot name="productHeader" v-bind="product"></slot></template>
        <template #sectionTabHeader="sectionTab"><slot name="sectionTabHeader" v-bind="sectionTab"></slot></template>
        <template #sectionTabContentHeader="sectionTab"><slot name="sectionTabContentHeader" v-bind="sectionTab"></slot></template>
        <template #sectionTableHeader="sectionTable"><slot name="sectionTableHeader" v-bind="sectionTable"></slot></template>
      </model-manufacturingSeries>
    </b-card-body>
  </b-card>

</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator'

import LoadingText from '@/components/utility/loading-text.vue'

import MxSite from '@/mixins/site'

import ModelManufacturingSeries from './manufacturingSeries.vue'
import { Series } from '@/store/model-viewer/types'

@Component({
  components: {
    'loading-text': LoadingText,
    'model-manufacturingSeries': ModelManufacturingSeries,
  },
})
export default class extends MxSite {
  @Prop()
  public series!: Series

  @Prop({ default: () => [] })
  public fields!: any[]

  @Prop()
  public customerNumber!: string
}
</script>
