<template>
  <b-card no-body>
    <b-card-header>
      <slot name="sectionTableHeader" v-bind="sectionTable"></slot>
      <template v-if="sectionTable.DisplayName.length > 0">
        {{ sectionTable.DisplayName }}
      </template>
      <template v-else>
        Default Section Table
      </template>
    </b-card-header>
    <b-card-body class="p-1 w-auto">
      <b-table-simple>
        <b-tr>
          <b-td v-if="hasHeaderSlot"></b-td>
          <b-th v-for="field in filteredFields" :key="field.key">
            {{ field.label }}
          </b-th>
        </b-tr>
        <model-row :model="model" :fields="filteredFields" v-for="model in sectionTable.Models" :key="model.PcnCatalogNumber">
          <template #rowHeader="model">
            <slot name="rowHeader" v-bind="model"></slot>
          </template>
        </model-row>
      </b-table-simple>
    </b-card-body>
  </b-card>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator'

import LoadingText from '@/components/utility/loading-text.vue'

import MxSite from '@/mixins/site'

import ModelRow from './row.vue'

import { SectionTable } from '@/store/model-viewer/types'

@Component({
  components: {
    'loading-text': LoadingText,
    'model-row': ModelRow,
  },
})
export default class extends MxSite {
  @Prop()
  public sectionTable!: SectionTable

  @Prop({ default: () => [] })
  public fields!: any[]

  @Prop()
  public customerNumber!: string

  get hasHeaderSlot() {
    return (
      Boolean(this.$slots.rowHeader) ||
      Boolean(typeof this.$scopedSlots.rowHeader === 'function')
    )
  }

  get filteredFields() {
    const models = this.sectionTable.Models
    const displayCustomerMaterialNumber = models.some(m => m.CustomerMaterials?.some(c => c.CustomerNumber === this.customerNumber))
    if (!displayCustomerMaterialNumber) {
      return this.fields.filter(f => f.key !== 'CustomerMaterials')
    }
    return this.fields
  }
}
</script>
