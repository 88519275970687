import _, { get } from 'lodash'

import { ActionTree } from 'vuex'
import State, {
  Product, Model, Section, SectionTab, SectionTable, SectionList, SectionImage,
  Facet, TableLayoutColumn, SectionRichText, SectionSpinSirv,
} from './types'
import { RootState, FormatType } from '../types'
import { Api } from '@/api/Api'

import { viewDetailsTableLayoutColumn, viewChangeRequestTableLayoutColumn, addItemToCart, loadingSection, showInventory, linkToParts } from './constants'

import { DivisionModel } from '../editor/types'
import { isCustomerAdminSite, isCustomerSite, isSalesRepSite } from '@/helpers/sites.helper'

const api = new Api()

const isNumber = (obj: any) => isFinite(obj) && !isNaN(parseFloat(obj))

// Workaround for https://dev.azure.com/Ricoh-TPI/TPI/_workitems/edit/692
const getSiteName = (rootState: RootState) => !rootState.site.CatalogId ? 'tpi' : rootState.site.Name
const getCatalogId = (rootState: RootState) => !rootState.site.CatalogId ? 5 : rootState.site.CatalogId
const getShowInventory = (rootState: RootState) => rootState.site.ShowInventory ? '&showInventory=true' : ''

export const actions: ActionTree<State, RootState> = {
  getProduct({ commit, rootState }, { productName, catalogId, isPreview }: { productName: string, catalogId?: number, isPreview?: boolean }): any {
    commit('productLoading')
    commit('imagesLoading')
    commit('clearActiveModel')
    let getProductUrl = ''
    if (isCustomerAdminSite(rootState.role) || isCustomerSite(rootState.site.Name) || isSalesRepSite(rootState.site.Name) || rootState.site.Name === 'cr' || rootState.site.Name === 'editor') {
      getProductUrl = '/product/product?product=' + productName +
        '&siteName=' + getSiteName(rootState) +
        '&catalogId=' + (catalogId ?? getCatalogId(rootState)) +
        '&isPreview=' + (isPreview ?? false)
    } else {
      getProductUrl = '/catalog/getproduct/' + productName
    }
    return api.fetch({
      url: getProductUrl,
    })
      .then(response => {
        if (!response.Successful) {
          throw response.Exception
        }

        const product: Product = response.Data
        // TODO: move up to actions?
        // push the Specification Tab if we have Specifications!
        product.loading = false

        product.Sections = loadingSection

        // yes, but no. This sets it to the list but with loading = true

        product.Images.forEach(image => { image.loading = true })
        commit('imagesLoaded', {
          images: product.Images,
        })

        commit('productLoaded', { product })
      }, () => {
        commit('productError')
      })
  },
  getProductSections({ commit, rootState }, { productName, routeSectionName, routeTabName, catalogId, isPreview }: { productName: string, routeSectionName: string, routeTabName: string, catalogId: number, isPreview?: boolean }): any {
    commit('clearActiveModel')
    let getProductsectionsUrl = ''
    if (isCustomerAdminSite(rootState.role) || isCustomerSite(rootState.site.Name) || isSalesRepSite(rootState.site.Name) || rootState.site.Name === 'cr' || rootState.site.Name === 'editor') {
      getProductsectionsUrl = '/product/productsections?product=' + productName +
        getShowInventory(rootState) +
        '&siteName=' + getSiteName(rootState) +
        '&catalogId=' + (catalogId ?? getCatalogId(rootState)) +
        '&isPreview=' + (isPreview ?? false)
    } else {
      getProductsectionsUrl = '/catalog/getproductsections/' + productName
    }
    return api.fetch({
      url: getProductsectionsUrl,
    })
      .then(response => {
        if (!response.Successful) {
          throw response.Exception
        }

        const product: Product = response.Data
        let activeSection = product.Sections[0]
        product.Sections.forEach(section => {
          if (routeSectionName && routeSectionName === section.Name) {
            activeSection = section
          }

          if (section.Tabs.length > 0) {
            section.Tabs = section.Tabs.sort((a, b) => a.OrderBy - b.OrderBy)
            let activeTab = section.Tabs[0]

            section.Tabs.forEach(sectionTab => {
              if (routeTabName && routeTabName === sectionTab.Name) {
                activeTab = sectionTab
              }
              sectionTab.Items.forEach(sectionItem => {
                switch (sectionItem.SectionItemType) {
                  case 'ModelTable': {
                    const sectionTable = sectionItem as SectionTable
                    sectionItem.key = 'sectionTable-' + sectionTable.SectionTableId

                    sectionTable.filteredModels = []
                    sectionTable.sorts = []
                    sectionTable.loading = true
                    sectionTable.fields = []
                    sectionTable.keyword = ''

                    sectionTable.TableLayout.Columns.forEach(tableLayoutColumn => {
                      const propertyName = tableLayoutColumn.PropertyName
                      const parentPropertyName = tableLayoutColumn.ParentPropertyName

                      // TODO: this is different for lists, perhaps!
                      switch (tableLayoutColumn.ParentFormatType) {
                        case FormatType.Delimited:
                        case FormatType.List: // TODO: should this also be Component?
                          tableLayoutColumn.key = parentPropertyName
                          break
                        default:
                          tableLayoutColumn.key = tableLayoutColumn.ParentPropertyName === 'Model'
                            ? propertyName
                            : parentPropertyName + '.' + propertyName
                      }

                      tableLayoutColumn.facets = []
                      // assuming that the default return is sorted by PCN - but we're not going to show that directly
                      // if (tableLayoutColumn.ColumnName === 'PCN_CATALOGNUMBER') {
                      //   tableLayoutColumn.sort = 1
                      //   sectionTable.sorts.push(tableLayoutColumn)
                      // }

                      tableLayoutColumn.filterVisible = false
                    })
                  }

                    break
                  case 'List': {
                    const sectionList = sectionItem as SectionList
                    sectionItem.key = 'sectionList-' + sectionList.SectionListId
                    break
                  }
                  case 'Image': {
                    const sectionImage = sectionItem as SectionImage
                    sectionItem.key = 'sectionImage-' + sectionImage.SectionImageId
                    break
                  }

                  case 'RichText': {
                    const sectionRichText = sectionItem as SectionRichText
                    sectionItem.key = 'sectionRichText-' + sectionRichText.SectionRichTextId
                    break
                  }
                  case 'SpinSirv': {
                    const sectionSpinSirv = sectionItem as SectionSpinSirv
                    sectionItem.key = 'sectionSpinSirv-' + sectionSpinSirv.SectionSpinSirvId
                    break
                  }
                }
              })
            })
            activeTab.active = true
          }
          activeSection.active = true
        })

        commit('productSectionsLoaded', { product })
      }, () => {
        commit('productSectionsError')
      })
  },
  loadImages({ commit, state: { product } }) {
    product.Images.forEach(i => { i.loading = false })

    commit('imagesLoaded', { images: product.Images })
  },

  loadTable({ commit, dispatch, rootState, rootGetters }, { routeSectionTable, routePcnCatalognumber, sectionTable }: { routeSectionTable: string, routePcnCatalognumber: string, sectionTable: SectionTable }) {
    // TODO: models goes away, Models becomes it
    // commit('modelsLoaded', { models: sectionTable.Models, sectionTable, page: 1 }) // TODO: Remove page!

    // TODO: move to table loaded!
    sectionTable.Models.forEach(model => {
      model.formattedValue = {}

      sectionTable.TableLayout.Columns
        // TODO: add more of these
        // TODO: what if the child value is what's formatted (e.g. a list of currencies?)
        // .filter(c => c.FormatType === FormatType.Number) // this means its a list of strings, obviously.
        // TODO: object vs. list vs. table, is it different?
        .forEach(c => {
          // override the field if it has an overide and that override is not null!
          // TODO: this is fairly hard-coded for DivisionModels
          if (c.OverrideProperty) {
            const overrideProps = c.OverrideProperty.split('.')
            if (overrideProps.length > 1 && overrideProps[0] === 'DivisionModels') { // TODO: maybe get that layoutproperty and check if it's a list?
              const divisionModel = model.DivisionModels.find((dm: DivisionModel) => dm.Division === rootState.division.DivisionName)
              const value = get(divisionModel, overrideProps[1])
              if (value !== undefined) {
                model[c.key] = value
              }
            }
          }
          switch (c.ParentFormatType) {
            case FormatType.Delimited:
              model.formattedValue[c.key] = model[c.key] ? model[c.key].join(', ') : ''
              break

            case FormatType.List: // TODO: what about lists that are formatted?
              model.formattedValue[c.key] = get(model, c.key) || []
              break

            default:
              switch (c.FormatType) {
                case FormatType.Number:
                  if (c.FormatValue && model[c.key]) {
                    const parsedValue = Number(model[c.key])

                    model.formattedValue[c.key] =
                      (!Number.isNaN(parsedValue)
                        ? Intl.NumberFormat('en-US', JSON.parse(c.FormatValue)).format(Number(model[c.key]))
                        : model[c.key]

                      )
                  } else {
                    model.formattedValue[c.key] = model[c.key]
                  }
                  break
                default:
                  model.formattedValue[c.key] = get(model, c.key) || ''
              }
          }
        })
    })

    sectionTable.TableLayout.Columns = [...sectionTable.TableLayout.Columns.sort((a, b) => a.OrderBy - b.OrderBy)]

    if (sectionTable.HideBlankColumns) {
      sectionTable.TableLayout.Columns = sectionTable.TableLayout.Columns.filter(c => sectionTable.Models.some(model => Array.isArray(model.formattedValue[c.key]) ? model.formattedValue[c.key].length > 0 : model.formattedValue[c.key] !== '')) // note: will this hide all "0s"
    }

    // TODO: if certain sites!
    if (rootState.site.EnableChangeRequest) {
      sectionTable.TableLayout.Columns.unshift(viewChangeRequestTableLayoutColumn)
    }

    if (sectionTable.DetailsPanel) {
      sectionTable.TableLayout.Columns.unshift(viewDetailsTableLayoutColumn)
    }

    if (rootGetters.currentCatalog.CartEnabled) {
      sectionTable.TableLayout.Columns.push(addItemToCart)
    }

    if (!rootGetters.currentCatalog.CartEnabled && sectionTable.HasCart) {
      sectionTable.TableLayout.Columns.push(linkToParts)
    }

    if (rootState.site.ShowInventory) {
      const inventoryIndex = 4
      sectionTable.TableLayout.Columns = [
        ...sectionTable.TableLayout.Columns.slice(0, inventoryIndex),
        showInventory,
        ...sectionTable.TableLayout.Columns.slice(inventoryIndex),
      ]
    }

    dispatch('formatFilteredModels', { sectionTable, models: sectionTable.Models })
    commit('setFilteredModels', { sectionTable, models: sectionTable.Models })
    dispatch('recalculateFacets', { sectionTable })

    // dispatch('getModels', { sectionTable }).then(() => {
    if (sectionTable.DisplayName === routeSectionTable && routePcnCatalognumber) {
      const index = sectionTable.Models.findIndex(x => x.PcnCatalogNumber === routePcnCatalognumber)
      if (index !== -1) {
        const model = sectionTable.Models[index]
        commit('setActiveModel', { model, tableLayout: sectionTable.TableLayout, sectionTable })
      }
    }

    sectionTable.fields = sectionTable.TableLayout.Columns.map(column => {
      const { DisplayName: label, key } = column
      const formatter = (value: string, key: string, model: Model) => model.formattedValue[column.key]
      const className =
        column.ParentFormatType === FormatType.List
          ? 'text-left'
          : column.Name === 'ViewDetails'
            ? 'tpi-details-cell'
            : 'text-center'

      return { label, class: className, column, key, formatter }
    })

    commit('setSectionTableLoading', { sectionTable, value: false })

    // })
  },
  setActiveTab({ commit }, { section, sectionTab }: { section: Section, sectionTab: SectionTab }) {
    commit('setActiveTab', { section, sectionTab })
    commit('clearActiveModel')
  },
  clearActiveTab({ commit }) {
    commit('clearActiveTab')
    commit('clearActiveModel')
  },
  setFeaturesActiveTab({ commit }) {
    commit('setFeaturesActiveTab')
    commit('clearActiveModel')
  },
  recalculateFacets({ commit, getters: { getFilteredModels } }, { sectionTable }: { sectionTable: SectionTable, updatedTableLayoutColumn: TableLayoutColumn }) {
    const facets: { [key: number]: Facet[] } = {}

    const columnFilteredModels: { [key: number]: Model[] } = {} // this will hold the filtered lists needed for recalculation, because we don't always need to recreate it every time

    sectionTable.TableLayout.Columns.forEach(tableLayoutColumn => {
      // note about the next commented line:
      // - I was doing "&& !columnUpdated" below, but that would only work for the second box, not worth the logic
      // const columnUpdated = (updatedTableLayoutColumn && updatedTableLayoutColumn.SectionTableLayoutColumnId === tableLayoutColumn.SectionTableLayoutColumnId)

      columnFilteredModels[tableLayoutColumn.SectionTableLayoutColumnId] =
        tableLayoutColumn.facets.some(f => f.selected) // if we have adjacents selected and we didn't just select another (AKA we haven't changed a thing)
          ? getFilteredModels({ sectionTable, excludeLayoutColumn: tableLayoutColumn }) // ignore ourself in the counts
          : sectionTable.filteredModels // if not, use what we have, no need to recalculate (AKA ref to real list)
    })

    commit('setColumnFilteredModels', { sectionTable, columnFilteredModels })

    sectionTable.TableLayout.Columns.forEach(tableLayoutColumn => {
      const { facets: oldFacets } = tableLayoutColumn

      const facetCounts = _.countBy(tableLayoutColumn.filteredModels, tableLayoutColumn.key)

      facets[tableLayoutColumn.SectionTableLayoutColumnId] = Object.keys(facetCounts).filter(key => key !== 'null' && key !== 'undefined').map(key => {
        const oldFacet = oldFacets.find(f => f.name === key)
        const selected = oldFacet ? oldFacet.selected : false

        return {
          name: key,
          displayName: key,
          count: facetCounts[key],
          selected,
          key: tableLayoutColumn.SectionTableLayoutColumnId + '-' + key,
        }
      })
    })
    commit('updateFacets', { sectionTable, facets })
  },
  applyFilters({ commit, dispatch, getters: { getFilteredModels } }, { sectionTable, tableLayoutColumn }: { sectionTable: SectionTable, tableLayoutColumn: TableLayoutColumn }) {
    const filteredList = getFilteredModels({ sectionTable }) as Model[]
    // const models = getSortedModels({ sectionTable, filteredList })
    const models = filteredList

    dispatch('formatFilteredModels', { sectionTable, models })
    commit('setFilteredModels', { sectionTable, models })
    commit('sortFilteredModels', { sectionTable }) // we have to incase they weren't visible!
    dispatch('recalculateFacets', { sectionTable, updatedTableLayoutColumn: tableLayoutColumn })
  },
  applySorts({ commit }, { sectionTable }: { sectionTable: SectionTable, tableLayoutColumn: TableLayoutColumn }) {
    commit('sortFilteredModels', { sectionTable })
  },
  applyKeyword({ commit, dispatch, getters: { getFilteredModels } }, { sectionTable }: { sectionTable: SectionTable }) {
    const filteredList = getFilteredModels({ sectionTable }) as Model[]
    const models = filteredList

    dispatch('formatFilteredModels', { sectionTable, models })
    commit('setFilteredModels', { sectionTable, models })
    commit('sortFilteredModels', { sectionTable }) // we have to incase they weren't visible!
    dispatch('recalculateFacets', { sectionTable })
  },
  clearColumnFilters({ commit, dispatch }, { sectionTable, tableLayoutColumn }: { sectionTable: SectionTable, tableLayoutColumn: TableLayoutColumn }) {
    commit('clearColumnFilters', { tableLayoutColumn })
    commit('clearColumnKeyword', { tableLayoutColumn })
    dispatch('applyFilters', { tableLayoutColumn, sectionTable })
  },
  formatFilteredModels({ rootGetters }, { sectionTable, models }: { sectionTable: SectionTable, models: Model[] }) {
    if (rootGetters['multiplier/hasCustomerMultiplier']) {
      const totalListColumn = sectionTable.TableLayout.Columns.find(c => c.PropertyName === 'TotalList')
      if (totalListColumn) {
        const format = totalListColumn.FormatValue ? JSON.parse(totalListColumn.FormatValue) : { style: 'currency', currency: 'USD' }

        models.forEach(model => {
          const parsedValue = Number(model.TotalList)
          model.formattedValue[totalListColumn.key] =
            (isNumber(parsedValue)
              ? Intl.NumberFormat('en-US', format).format(rootGetters['multiplier/hasCustomerMultiplier'] ? rootGetters['multiplier/getMultiplierPrice'](model.PcnCatalogNumber, model.ProductHierarchy, model.TotalList) : Number(model[totalListColumn.key]))
              : model[totalListColumn.key]
            )
        })
      }
    }
  },
}
