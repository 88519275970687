<template>
  <b-card no-body>
    <b-card-header class="py-1" header-tag="h3" v-if="section.DisplayName">{{section.DisplayName}}</b-card-header>
    <b-card-body v-if="section.Tabs.length > 0" class="p-1 w-auto">
      <b-tabs content-class="mt-3">
        <model-tab :sectionTab="sectionTab" :fields="fields" v-for="sectionTab in section.Tabs" :key="sectionTab.SectionTabId" :customerNumber="customerNumber">
          <template #sectionTabHeader="sectionTab"><slot name="sectionTabHeader" v-bind="sectionTab"></slot></template>
          <template #sectionTabContentHeader="sectionTab"><slot name="sectionTabContentHeader" v-bind="sectionTab"></slot></template>
          <template #sectionTableHeader="sectionTable"><slot name="sectionTableHeader" v-bind="sectionTable"></slot></template>
          <template #rowHeader="model"><slot name="rowHeader" v-bind="model"></slot></template>
        </model-tab>
      </b-tabs>
    </b-card-body>
  </b-card>

</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator'

import LoadingText from '@/components/utility/loading-text.vue'

import MxSite from '@/mixins/site'

import ModelTab from './tab.vue'
import { Section } from '@/store/model-viewer/types'

@Component({
  components: {
    'loading-text': LoadingText,
    'model-tab': ModelTab,
  },
})
export default class extends MxSite {
  @Prop()
  public section!: Section

  @Prop({ default: () => [] })
  public fields!: any[]

  @Prop()
  public customerNumber!: string
}
</script>
