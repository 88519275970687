<template>
  <b-container fluid>
    <b-container fluid class="px-0 d-flex justify-content-end align-items-center my-2" style="gap:5px" v-if="sectionTable">
      <b-button v-if="site.EnableExport" variant="outline-primary" :href="getExportUrl()" target="_blank" size="sm">
        <b-icon icon="table"  aria-hidden="true" title="Download data in Excel format.">
        </b-icon> Download Excel
      </b-button>
      <b-button v-if="site.EnableChangeRequest" title="Edit Table Models" variant="outline-primary" @click.stop="onMultipleModelChangeRequestClicked(sectionTable)" size="sm">
        <b-icon icon="pencil-square" aria-hidden="true"></b-icon> Edit All
      </b-button>
      <b-form-input :id="'input-table-keyword-' + sectionTable.key" size="sm" class="w-25"
        v-model="sectionTable.keyword" placeholder="Keyword Search" debounce="250">
      </b-form-input>
    </b-container>
    <b-row no-gutters>
      <b-col :md="(sideBarModel.visible && containsActivePCN) ? 6 : 12" sm="12" v-if="!isTableHidden"
        :class="{ 'd-none d-sm-block': (sideBarModel.visible && containsActivePCN) }">
        <b-table ref="bSectiontable"
          class="table-table w-auto"
          v-if="sectionTable && !sectionTable.loading && !sectionTable.Models.loading && sectionTable.fields.length > 0"
          :class="{ 'min-table': isFilterMode }"
          striped hover small bordered responsive
          selectable
          select-mode="single"
          sticky-header="500px"
          :items="sectionTable.filteredModels"
          :fields="fields"
          :no-local-sorting="true"
          @sort-changed="onSort"
          :per-page="0"
          @row-clicked="onRowClicked"
          primary-key="PcnCatalogNumber">
          <template v-slot:head()="fieldData">
            <b-container fluid>
              <b-row>
                <b-col :cols="!screen.lt.md || (isFilterMode && (fieldData.field.column.SortBy || fieldData.field.column.FilterBy || fieldData.field.column.KeywordSearchBy)) ? 9 : 12" v-if="screen.lt.md" class="pr-0">
                  <template v-if="fieldData.field.key != 'FingoodTpiMfgMod' && fieldData.field.key != 'DivisionModelNumbers'">
                    <b-form-select style="text-align: center;"
                      :value="mobileColumnKey"
                      :options="columns"
                      value-field="key"
                      text-field="DisplayName"
                      size="sm"
                      @input="setMobileColumnKey({ value: $event })">
                      <template v-slot:first>
                        <b-form-select-option value="">Choose field</b-form-select-option>
                      </template>
                    </b-form-select>
                  </template>
                </b-col>
                <b-col v-if="!screen.lt.md || (isFilterMode && (fieldData.field.column.SortBy || fieldData.field.column.FilterBy || fieldData.field.column.KeywordSearchBy))" :cols="screen.lt.md && fieldData.field.key != 'FingoodTpiMfgMod' && fieldData.field.key != 'DivisionModelNumbers' ? 3 : 12" :class="screen.lt.md && fieldData.field.key != 'FingoodTpiMfgMod' && fieldData.field.key != 'DivisionModelNumbers' ? 'pl-0' : ''">
                  <b-dropdown no-flip size="sm" no-caret :variant="isFilterMode ? 'outline-secondary' : 'none'" split-variant="outline-primary" v-if="fieldData.field.column.SectionTableLayoutColumnId > 0" :title="fieldData.field.column.DisplayDescription && fieldData.field.column.DisplayDescription.length > 0 ? fieldData.field.column.DisplayDescription : fieldData.field.column.DisplayName" boundary="window" block class="tpi-disabled" :disabled="!isFilterMode" menu-class="w-100 tpi-dropdown">
                    <template v-slot:button-content>
                      <b-button title="Click Column to Sort and Filter" variant="outline-secondary" size="sm" flush
                        style="border: 0; padding: 0"
                        v-if="isFilterMode && (fieldData.field.column.SortBy || fieldData.field.column.FilterBy || fieldData.field.column.KeywordSearchBy)">
                        <b-badge pill variant="secondary"
                          title="Filter Count"
                          style="position: absolute; top: 4px; right: 4px;"
                          v-if="isFiltered(fieldData.field.column)">
                          {{ selectedFilterCount(fieldData.field.column) }}
                        </b-badge>
                        <b-badge pill variant="secondary"
                          title="Sort"
                          style="position: absolute; top: 4px; left: 4px; padding: 2px"
                          v-if="fieldData.field.column.sort != 0">
                          <b-icon icon="arrow-up" v-if="fieldData.field.column.sort == -1" aria-hidden="true"></b-icon>
                          <b-icon icon="arrow-down" v-if="fieldData.field.column.sort == 1" aria-hidden="true"></b-icon>
                        </b-badge>

                      </b-button>
                      <template v-if="!screen.lt.md || fieldData.field.key == 'FingoodTpiMfgMod' || fieldData.field.key == 'DivisionModelNumbers'">
                        {{ fieldData.label }}
                      </template>
                      <b-icon :icon="isFiltered(fieldData.field) ? 'funnel-fill' : 'funnel'" aria-hidden="true" v-if="isFilterMode && (fieldData.field.column.SortBy || fieldData.field.column.FilterBy || fieldData.field.column.KeywordSearchBy)">
                      </b-icon>
                    </template>

                    <template v-if="isFilterMode">
                      <template v-if="(fieldData.field.column.SortBy || fieldData.field.column.FilterBy || fieldData.field.column.KeywordSearchBy)">
                        <b-dropdown-header class="display-description">{{ fieldData.field.column.DisplayDescription }}</b-dropdown-header>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-group v-if="fieldData.field.column.SortBy">
                          <b-button-toolbar class="col-md-4 text-center">
                            <b-button-group size="sm">
                              <b-button title="Z to A" variant="outline-dark"
                                @click="onSort({ tableLayoutColumn: fieldData.field.column, sort: -1 })">
                                <b-icon icon="arrow-up" :variant="fieldData.field.column.sort == -1 ? 'success' : ''" aria-hidden="true"></b-icon>
                              </b-button>
                              <b-button title="A to Z" variant="outline-dark"
                                @click="onSort({ tableLayoutColumn: fieldData.field.column, sort: 1 })">
                                <b-icon icon="arrow-down" :variant="fieldData.field.column.sort == 1 ? 'success' : ''" aria-hidden="true"></b-icon>
                              </b-button>
                            </b-button-group>
                          </b-button-toolbar>

                        </b-dropdown-group>
                        <template v-if="fieldData.field.column.KeywordSearchBy">
                          <b-dropdown-divider></b-dropdown-divider>
                          <b-dropdown-form>
                            <b-form-input :id="'input-search-' + sectionTable.key + '-' + fieldData.field.column.key"
                              v-model="fieldData.field.column.keyword" placeholder="Search"
                              @input="onKeywordInput({ tableLayoutColumn: fieldData.field.column })"></b-form-input>
                          </b-dropdown-form>
                        </template>
                        <template v-if="fieldData.field.column.FilterBy">
                          <b-dropdown-divider></b-dropdown-divider>
                          <b-dropdown-form class="filter-form">
                            <template v-for="facet in fieldData.field.column.facets">
                              <b-form-checkbox :key="'facet-' + facet.key"
                                v-model="facet.selected"
                                @input="onFacetClick({ tableLayoutColumn: fieldData.field.column })"
                                v-if="facet.count > 0">
                                {{ facet.displayName != '' ? facet.displayName : '[Blank]' }}
                                <b-badge pill variant="secondary">{{ facet.count }} </b-badge>
                              </b-form-checkbox>
                            </template>
                          </b-dropdown-form>
                        </template>
                        <template v-if="fieldData.field.column.FilterBy || fieldData.field.column.KeywordSearchBy">
                          <b-dropdown-divider></b-dropdown-divider>
                          <b-dropdown-item-button @click="clearFilters(fieldData.field.column)">
                            <b-icon icon="x" variant="danger" aria-hidden="true"></b-icon>
                            Clear
                          </b-dropdown-item-button>
                        </template>
                      </template>
                    </template>
                  </b-dropdown>
                </b-col>
                <b-col v-else-if="fieldData.field.key == 'FingoodTpiMfgMod' || fieldData.field.key == 'DivisionModelNumbers'">
                  {{ fieldData.label }}
                </b-col>
              </b-row>
            </b-container>
          </template>
          <template v-slot:cell(viewDetails)="row">
            <b-button
              variant="outline-primary"
              aria-controls="details-sidebar"
              :aria-expanded="sideBarModel && sideBarModel.visible ? 'true' : 'false'"
              @click="onRowClicked(row.item)"
              size="sm"
              class="px-1 py-0"
              :pressed="sideBarModel.model.PcnCatalogNumber === row.item.PcnCatalogNumber && containsActivePCN">
              <b-icon-info-circle aria-hidden="true"></b-icon-info-circle>
            </b-button>
          </template>
          <template v-slot:cell(changeRequest)="row">
            <b-button
              variant="outline-primary"
              aria-controls="details-sidebar"
              :aria-expanded="sideBarModel && sideBarModel.visible ? 'true' : 'false'"
              @click="onRowChangeRequestClicked(row.item.PcnCatalogNumber)"
              size="sm"
              class="px-1 py-0"
              :pressed="sideBarModel.model.PcnCatalogNumber === row.item.PcnCatalogNumber && containsActivePCN"
              title="Create Change Request">
              <b-icon-pencil-fill aria-hidden="true"></b-icon-pencil-fill>
            </b-button>
          </template>
          <template v-slot:cell(options)="row">
            <b-button
              variant="link"
              aria-controls="details-sidebar"
              @click="onRowClicked(row.item)"
              size="sm"
              class="px-1 py-0">
              <b-icon icon="cart-plus"></b-icon>
            </b-button>
          </template>

          <template v-slot:cell()="data">
            <template v-if="data.field.column.ParentFormatType === formatType.List">
              <component :is="data.field.column.ParentFormatValue || 'ul'" v-for="value in data.value" :key="value.key" class="text-center">
                <component :is="data.field.column.FormatValue">
                  <template v-if="data.field.column.PropertyType == propertyType.String">{{ value }}</template>
                  <template v-else>{{ getObjectValue(value, data.field.column) }}</template>
                </component>
              </component>
            </template>
            <template v-else-if="data.field.column.PropertyName == propertyType.Image">
              <b-img
                v-if="(sectionTable.TableLayout.DisplayName ?? '').toLocaleLowerCase().includes('replacement') || data.value"
                class="text-center" fluid
                :src="data?.value?.ImagePath + '?width=50&height=50'"
                @error="event => _loadErrorImage(event, '50', '50')" />
            </template>
            <template v-else>
              {{ data.value }}
            </template>
          </template>
          <template v-if="sectionTable.HasCart" v-slot:cell(addItemToCart)="row">
            <b-button
              v-b-modal.modalQuantity
              size="md"
              variant="info background-color-primary"
              class="cart-btn"
              ref="quantityModal"
              @click="setActiveItem(row.item)">
              <b-icon icon="cart-plus" size="sm" aria-hidden="true"></b-icon>
            </b-button>
          </template>
          <template v-if="sectionTable.HasCart && !catalog.CartEnabled" v-slot:cell(linkToParts)="row">
            <a :href="generatePartUrl(row.item)">Order on our Parts Site</a>
          </template>
          <template v-slot:cell(showInventory)="row">
            <modal-inventory :item="row.item"></modal-inventory>
          </template>
        </b-table>
        <template v-if="sectionTable.loading">
          <loading-text></loading-text>
        </template>
      </b-col>

      <b-col :md="sectionTable.Cols == 12 ? 6 : 12" sm="12" class="detailsTest" v-if="sideBarModel.visible && containsActivePCN">
        <portal :disabled="!showPrintView" to="print-bottom">
          <b-card no-body class="detail-card">
            <b-card-header class="py-1" header-tag="h2">
              Model Details
              <b-button-toolbar class="float-md-right">
                <b-button-group size="sm" class="mx-1">
                  <b-button title="Print" variant="light" @click="clickPrint()">
                    <b-icon icon="newspaper" aria-hidden="true"></b-icon>
                  </b-button>
                  <b-button title="Close" variant="light" @click="closeDetails()">
                    <b-icon icon="x" aria-hidden="true"></b-icon>
                  </b-button>
                </b-button-group>
              </b-button-toolbar>
            </b-card-header>
            <b-card-body class="product-details-scroll">
              <model-details-card v-if="!sideBarModel.model.isNone" :model="sideBarModel.model" :tableLayout="sideBarModel.tableLayout" :hasCart="catalog.CartEnabled && sectionTable.HasCart" :sectionTable="sectionTable">
              </model-details-card>
            </b-card-body>
          </b-card>
        </portal>
      </b-col>
    </b-row>
    <modal-select-quantity :modalQuantityVisible="modalQuantityVisible" @closeModal="modalQuantityVisible = false"></modal-select-quantity>
    <model-change-request v-if="site.EnableChangeRequest" :pcnCatalogNumber="selectedChangeRequestPcnCatalogNumber" @closed="modelChangeRequestClosed()" />
    <multiple-model-request v-if="site.EnableChangeRequest" :sectionTableId=selectedSectionTableId @closed="multipleChangeRequestClosed()" />
  </b-container>
</template>

<script lang="ts">
import _ from 'lodash'
import { defineAsyncComponent } from 'vue'
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Product, SectionTable, TableLayoutColumn, Model, SideBarItem } from '../store/product/types'
import LoadingText from '@/components/utility/loading-text.vue'
import ModelDetailsCard from '@/components/model-details-card.vue'
import { BTable } from 'bootstrap-vue'
import modalSelectQuantity from './cart/modal-cart-select-quantity.vue'
import modalInventory from './inventory/modal-inventory.vue'
import MxSite from '@/mixins/site'
import { CartItem } from '@/store/cart/types'
import { blankCartItem } from '@/store/cart/constants'

const nsProduct = namespace('product')
const nsRequest = namespace('request')
const nsCart = namespace('cart')

@Component({
  components: {
    'loading-text': LoadingText,
    'model-details-card': ModelDetailsCard,
    'modal-select-quantity': modalSelectQuantity,
    'modal-inventory': modalInventory,
    'model-change-request': defineAsyncComponent(() => import('../components/modals/model-change-request.vue')),
    'multiple-model-request': defineAsyncComponent(() => import('@/components/request/multiple-model-request.vue')),
  },
})
export default class extends Mixins(MxSite) {
  public selectedChangeRequestPcnCatalogNumber = ''
  public selectedSectionTableId = 0

  @Prop()
  public product!: Product

  @Prop()
  public sectionTable!: SectionTable

  public mobileColumnKey = ''
  public keyword = ''

  @nsProduct.Getter('sideBarModel')
  public sideBarModel!: SideBarItem

  @nsProduct.Action('getModels')
  public getModels: any

  @nsProduct.Mutation('setCurrentPage')
  public setCurrentPage: any

  @nsProduct.Mutation('toggleFilterVisible')
  public toggleFilterVisible: any

  @nsProduct.Mutation('updateSort')
  public updateSort: any

  @nsProduct.Action('clearColumnFilters')
  public clearColumnFilters: any

  @nsProduct.Mutation('updateActiveModel')
  public updateActiveModel: any

  @nsProduct.Mutation('setActiveModel')
  public setActiveModel: any

  @nsProduct.Mutation('clearActiveModel')
  public clearActiveModel: any

  @nsProduct.Action('recalculateFacets')
  public recalculateFacets!: any

  @nsProduct.Action('applyFilters')
  public applyFilters!: any

  @nsProduct.Action('applyKeyword')
  public applyKeyword!: any

  @nsProduct.Action('applySorts')
  public applySorts!: any

  @nsProduct.Mutation('setPrintView')
  public setPrintView: any

  @nsProduct.State('showPrintView')
  public showPrintView!: boolean

  @nsCart.Action('setActiveCartItem')
  private setActiveCartItem: any

  @nsRequest.Mutation('setSelectedSectionTable')
  public setSelectedSectionTable: any

  public urlExportSectionController = '/api/export/exportSectionTable?'

  public created() {
    this.setPrintView({ value: false })
  }

  @Watch('sectionTable.filteredModels')
  public watchSectionTable() {
    const { query } = this.$route
    if (this.site.HasPricing && query.addToCart === '1' && this.sectionTable.DisplayName === query.sectionTable) {
      const toBeAddedItem = this.sectionTable.filteredModels.find(x => x.PcnCatalogNumber === query.catalogNumber)
      if (toBeAddedItem) {
        this.setActiveItem(toBeAddedItem)
      }
    }
  }

  public modalQuantityVisible = false

  public setActiveItem(itemObj: any) {
    const cartItem: CartItem = blankCartItem
    const item = itemObj
    cartItem.pcnCatalogNumber = item.PcnCatalogNumber || ''
    cartItem.fingoodTpiMfgMod =
      item.FingoodTpiMfgMod ?? item.Details.Description?.toString()
    cartItem.description =
      item.SapModelDescription != null
        ? item.SapModelDescription
        : item.Descriptions && item.Descriptions.length > 0
          ? item?.Descriptions[0]
          : ''
    cartItem.quantity = 0
    cartItem.price = item.TotalList ?? item.price
    cartItem.totalPrice = item.totalPrice ?? cartItem.quantity * cartItem.price
    cartItem.imgThumbnail = item?.Image?.ImagePath || ''
    cartItem.shippingStrategyId = item.ShippingStrategyId
    this.setActiveCartItem({ cartItem }, { item: cartItem })
    this.modalQuantityVisible = true
  }

  get containsActivePCN(): boolean {
    const { sectionTable, sideBarModel } = this
    return sideBarModel.model && sectionTable.Models.some(model => model.PcnCatalogNumber === sideBarModel.model.PcnCatalogNumber && sideBarModel.sectionTable.DisplayName === sectionTable.DisplayName)
  }

  // TODO: product?
  @Watch('product')
  public watchProduct() {
    this.setPrintView({ value: false })
  }

  public clickPrint() {
    this.setPrintView({ value: true })
    setTimeout(() => { window.print() }, 500)
  }

  public closeDetails() {
    const { query } = this.$route

    const newQuery = _.assign({}, query)

    this.clearActiveModel()
    delete newQuery.catalogNumber

    this.$router.replace({ query: newQuery })
  }

  get isTableHidden(): boolean {
    const { sideBarModel, containsActivePCN, sectionTable } = this
    return sideBarModel.visible && containsActivePCN && sectionTable.Cols < 12
  }

  get isFilterMode() {
    return this.sectionTable && this.sectionTable.Models && this.sectionTable.Models && !this.sectionTable.HideTableFilters
  }

  get fields() {
    const { sectionTable, screen, mobileColumnKey } = this
    if (!screen.lt.md) { return sectionTable.fields }
    const secondField = mobileColumnKey === ''
      ? { label: '', class: '', column: '', key: 'dropdown', formatter: () => undefined }
      : sectionTable.fields.find(f => f.key === mobileColumnKey)
    // TODO: FingoodTpiMfgMod will change with Model Number trickery for multisite
    return [sectionTable.fields.find(f => f.key === 'FingoodTpiMfgMod' || f.key === 'DivisionModelNumbers'), secondField]
  }

  get columns() {
    return this.sectionTable.TableLayout.Columns.filter(c => c.key !== 'FingoodTpiMfgMod' && c.key !== 'DivisionModelNumbers' && !c.isCustom)
  }

  public onSort({ tableLayoutColumn, sort }: { tableLayoutColumn: TableLayoutColumn, sort: number }) {
    const { sectionTable } = this
    this.updateSort({ sectionTable, tableLayoutColumn, sort })
    // this.setCurrentPage({ sectionTable: this.sectionTable, page: 1 })

    this.applySorts({ sectionTable, tableLayoutColumn, sort })
  }

  public onPageChange(page: number) {
    const { sectionTable } = this
    this.setCurrentPage({ sectionTable, page })
  }

  // public applyFilter() {
  //   this.getModels({ sectionTable: this.sectionTable }) // , page: 1
  // }

  public onKeywordInput({ tableLayoutColumn }: { tableLayoutColumn: TableLayoutColumn }) {
    const { sectionTable } = this
    if (sectionTable) {
      // const tableLayoutColumn = sectionTable.TableLayout.Columns.find(c => c.key === fieldKey)
      // this.updateKeyword({ tableLayoutColumn, keyword })
      this.applyFilters({ sectionTable, tableLayoutColumn })
    }
  }

  public onFacetClick({ tableLayoutColumn }: { tableLayoutColumn: TableLayoutColumn }) {
    const { sectionTable } = this
    if (sectionTable) {
      // const tableLayoutColumn = sectionTable.TableLayout.Columns.find(c => c.key === fieldKey)
      // this.updateFacet({ tableLayoutColumn, facet })
      this.applyFilters({ sectionTable, tableLayoutColumn })
    }
  }

  public selectedFilterCount(tableLayoutColumn: TableLayoutColumn): number {
    return tableLayoutColumn.facets.filter(facet => facet.count > 0 && facet.selected).length +
      (tableLayoutColumn.keyword ? 1 : 0)
  }

  public isFiltered(tableLayoutColumn: TableLayoutColumn): boolean {
    if (!tableLayoutColumn.facets) {
      return false
    }
    return tableLayoutColumn.facets.filter(facet => facet.count > 0 && facet.selected).length > 0 ||
      (!!tableLayoutColumn.keyword)
  }

  public clearFilters(tableLayoutColumn: TableLayoutColumn) {
    const { sectionTable } = this
    this.clearColumnFilters({ sectionTable, tableLayoutColumn })
  }

  public onRowClicked(item: Model) {
    if ((this.sectionTable as SectionTable).DetailsPanel) {
      this.setSelected(item)
    }
  }

  public setMobileColumnKey({ value }: { value: string }) {
    this.mobileColumnKey = value
  }

  public setSelected(model: Model) {
    const table = (this.sectionTable as SectionTable)
    const tableLayout = table.TableLayout
    const { query } = this.$route
    const newQuery = _.assign({}, query)
    if (this.sideBarModel.model.PcnCatalogNumber === model.PcnCatalogNumber && this.sideBarModel.sectionTable.DisplayName === table.DisplayName) {
      this.clearActiveModel()
      delete newQuery.catalogNumber
      delete newQuery.sectionTable
    } else {
      this.setActiveModel({ model, tableLayout, sectionTable: table })
      // history.pushState({}, "URL Rewrite Example", "https://stackoverflow.com/example")
      newQuery.sectionTable = table.DisplayName
      newQuery.catalogNumber = model.PcnCatalogNumber
    }

    this.$router.replace({ query: newQuery })
  }

  public sideBarChanged(visible: boolean) {
    if (!visible) {
      this.updateActiveModel({ model: undefined, tableLayout: undefined }) // We just want to ensure nothing is selected
    }
  }

  // TODO: Debounce?
  @Watch('sectionTable.keyword')
  public keywordChanged() {
    const { sectionTable } = this
    if (sectionTable) {
      this.applyKeyword({ sectionTable }) // this way it will recalculate the values in the filters!
    }
  }

  // TODO: what's with this index?
  @Watch('sideBarModel.index')
  public onModelChanged(indexNew: number, indexOld: number) {
    if (indexOld !== indexNew) {
      const bTable = (this.$refs.bSectiontable as unknown as BTable)
      if (bTable) {
        bTable.selectRow(indexNew)
      }
    }
  }

  getExportUrl() {
    return this.urlExportSectionController + 'siteName=' + this.site.Name + '&sectionTableId=' + encodeURIComponent(this.sectionTable.SectionTableId ?? '') + '&productId=' + encodeURIComponent(this.product.ProductId ?? '') + '&showinventory=' + encodeURIComponent(this.site.ShowInventory)
  }

  public onRowChangeRequestClicked(pcnCatalogNumber: string) {
    this.selectedChangeRequestPcnCatalogNumber = pcnCatalogNumber
  }

  public modelChangeRequestClosed() {
    this.selectedChangeRequestPcnCatalogNumber = ''
  }

  public generatePartUrl(model: Model): string {
    const currentUrl = window.location.href
    const url = new URL(currentUrl)
    url.host = process.env.VUE_APP_PARTS_SITE_URL?.toString() || ''
    const table = (this.sectionTable as SectionTable)
    const params = new URLSearchParams(url.search)
    params.set('sectionTable', table.DisplayName)
    params.set('catalogNumber', model.PcnCatalogNumber)
    params.set('addToCart', '1')
    url.search = params.toString()
    return url.protocol + '//' + url.host + url.pathname + url.search + url.hash
  }

  public onMultipleModelChangeRequestClicked(sectionTable: any) {
    this.setSelectedSectionTable({ sectionTable })
    this.selectedSectionTableId = sectionTable.SectionTableId
  }

  public multipleChangeRequestClosed() {
    this.selectedSectionTableId = 0
  }
}
</script>
<style>
.detailsTest .detail-card .product-details-scroll .detail-container {
  max-height: 500px;
  overflow: auto;
}

.section-table-container .table {
  width: auto !important;
}

.tpi-dropdown {
  min-width: 250px !important;
}

.tpi-disabled button {
  opacity: 1 !important;
}

.tpi-details-cell {
  width: 38px;
}

/*
.section-table-container .table-responsive {
  width: auto !important;
} */
</style>
<style scoped>
ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

/* .table-card {
  min-height: 506px;
} */
/* .table-table {
  min-height: 506px;
} */

.min-table {
  min-height: 500px
}

.display-description .dropdown-header {
  padding: 0.5rem 0.5rem;
  max-width: 200px;
  white-space: normal;
}

.filter-form {
  max-height: 200px;
  overflow: auto;
  /* min-width: 200px; */
}

/* If we disable details for certain Layouts, this will need fixing */
.scroll-dropdown .dropdown-menu {
  max-height: 400px;
  max-width: 500px;
  overflow-y: auto;
}

.vertical-centered {
  display: flex;
  align-items: center;
}
</style>
