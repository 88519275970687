import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import State from './types'
import { RootState } from '../types'
import { blankResponseArray } from '../constants'

export const state: State = {
  error: false,
  visibleModelsResults: { ...blankResponseArray, loading: false },
  visibleModelResultsByMaterialGroup: { ...blankResponseArray, loading: false },
  visibleMaterialGroupResults: { ...blankResponseArray, loading: false },
}

const namespaced = true

export const search: Module<State, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
