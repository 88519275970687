import { render, staticRenderFns } from "./main-header.vue?vue&type=template&id=4e9f8f42&scoped=true"
import script from "./main-header.vue?vue&type=script&lang=ts"
export * from "./main-header.vue?vue&type=script&lang=ts"
import style0 from "./main-header.vue?vue&type=style&index=0&id=4e9f8f42&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+com_babb32925cd4b8a8c1892c895ee75fdf/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e9f8f42",
  null
  
)

export default component.exports