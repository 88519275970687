<template>
  <b-navbar
    type="dark"
    class="sale-rep-nav background-color-primary py-0"
    fixed="top">
    <b-navbar-brand :href="site.LogoLink" v-if="site.LogoPath">
      <img :src="site.LogoPath" alt="white_logo" class="logo" />
    </b-navbar-brand>
    <b-navbar-nav v-if="!isMobile">
      <b-nav-item :to="{ name: 'home' }">{{ site.BrowserTitle }}</b-nav-item>
      <b-nav-item
        v-for="(item, index) in navItems.filter((x) => x.show)"
        :key="index"
        :to="item.to"
        :active="item.active"
        :class="item.class">
        {{ item.text }}
      </b-nav-item>
      <b-nav-item-dropdown text="Catalogs" class="nav-items-default" right  v-if="site.ShowCatalog && (userSalesRepPermissions.length > 0 || customerPermissions.length > 0) && catalogs.length > 0">
        <b-dropdown-item v-for="catalog in catalogs" :key="catalog.CatalogId" :to="{ name: 'catalog', query: { catalogId: catalog.CatalogId } }" target="_blank" class="nav-items-default">
          {{ catalog.DisplayName }}
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <div class="flex-grow-1"></div>
    <b-navbar-nav class="ml-auto pr-3" v-if="_isSalesRepSite(site.Name) && userSalesRepPermissions.length > 0">
      <b-navbar-nav right>
        <search-input></search-input>
      </b-navbar-nav>
    </b-navbar-nav>
    <b-navbar-nav v-if="!isMobile">
      <b-nav-item-dropdown right no-caret v-if="currentUser.HasAccess">
        <template v-slot:button-content>
          <div class="d-flex align-items-center">
            <b-avatar :text="userInitials" size="sm"></b-avatar>
            <div class="ml-2">
              <div>{{ currentUser.Name }}</div>
              <div class="small">{{ currentUser.Role }}</div>
            </div>
            <b-icon icon="chevron-down" class="ml-2"></b-icon>
          </div>
        </template>
        <b-dropdown-item :to="{ name: 'user-profile' }">Profile</b-dropdown-item>
        <b-dropdown-item :to="{ name: 'change-password', params: { change: true } }">Change password</b-dropdown-item>
        <b-dropdown-item @click="onLogout()">Logout</b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <b-button v-b-toggle.sidebar-1 variant="outline-light" v-if="isMobile && currentUser.HasAccess">
      <b-icon icon="list"></b-icon>
    </b-button>
    <b-sidebar id="sidebar-1" shadow body-class="background-color-primary" header-class="background-color-primary border-bottom" footer-class="background-color-primary border-top" :backdrop="true">
      <template #header="{ hide }">
        <b-navbar-nav class="align-items-center w-100">
          <img :src="site.LogoPath" alt="white_logo" class="logo" />
          <b-nav-item class="h6 mb-0 font-weight-bold" :to="{ name: 'home' }">{{
            site.BrowserTitle
          }}</b-nav-item>
          <b-button variant="outline-light" @click="hide"><b-icon icon="chevron-left"></b-icon></b-button>
        </b-navbar-nav>
      </template>
      <b-nav vertical variant="dark">
        <b-nav-item v-for="(item, index) in navItems.filter((x) => x.show)" :key="index" :to="item.to" :active="item.active" :class="item.class">
          {{ item.text }}
        </b-nav-item>
      </b-nav>
      <template #footer>
        <b-navbar-nav class="justify-content-between">
          <b-nav-item
            :to="{ name: 'user-profile' }"
            v-if="currentUser.HasAccess">
            <div class="d-flex align-items-center justify-content-center">
              <b-avatar :text="userInitials" size="sm"></b-avatar>
              <div class="ml-2">
                <div>{{ currentUser.Name }}</div>
                <div class="small">{{ currentUser.Role }}</div>
              </div>
            </div>
          </b-nav-item>
          <b-nav-item :to="{ name: 'change-password', params: { change: true } }" v-if="currentUser.HasAccess">Change password</b-nav-item>
          <b-nav-item @click="onLogout()" v-if="currentUser.HasAccess">Logout</b-nav-item>
        </b-navbar-nav>
      </template>
    </b-sidebar>
  </b-navbar>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'
import MxSite from '@/mixins/site'
import { isMobile, handleResize } from '@/common/helpers/responsive-helper'
import { ResourceType } from '@/store/security/types'
import type { NavItem } from '@/helpers/nav.helper'
import SearchInput from '../search/search-input.vue'
@Component({
  components: {
    SearchInput,
  },
})
export default class extends Mixins(MxSite) {
  public isMobile = isMobile()

  public get userInitials(): string {
    if (!this.currentUser.Name) {
      return this.currentUser.Username.charAt(0).toUpperCase()
    }

    const names = [this.currentUser.Name, this.currentUser.LastName ?? '']
    return names
      .map((n) => n.charAt(0))
      .join('')
      .toUpperCase()
  }

  public onLogin() {
    const { $router } = this
    $router.push('/authentication/login')
  }

  public onLogout() {
    this.logout().then(() => {
      const { $router } = this
      $router.replace('/authentication/login')
    })
  }

  public created() {
    handleResize((isMobile) => {
      this.isMobile = isMobile
    })
  }

  public get customerPermissions() {
    const permissions = (this.currentUser.ResourcePermissions ?? []).filter(
      (item) => item.Type === ResourceType.Customer,
    )
    return this._isCustomerSite(this.site.Name) ? permissions : []
  }

  public get userSalesRepPermissions() {
    const permissions = (this.currentUser.ResourcePermissions ?? []).filter(
      (item) => item.Type === ResourceType.SalesRep,
    )
    return this._isSalesRepSite(this.site.Name) ? permissions : []
  }

  public get isCurrentRoute(): (routeName: string) => boolean {
    return (routeName: string) => this.$route.name === routeName
  }

  public get navItems(): NavItem[] {
    return [
      {
        text: 'Orders',
        to: { name: 'home' },
        active: this.isCurrentRoute('home'),
        class: 'nav-items-default',
        show:
          this.customerPermissions.length > 0 ||
          this.userSalesRepPermissions.length > 0,
      },
      {
        text: 'Inquiries',
        to: { name: 'list-inquiry' },
        active: this.isCurrentRoute('list-inquiry'),
        class: 'nav-items-default',
        show:
          this._isCustomerSite(this.site.Name) &&
          this.customerPermissions.length > 0,
      },
      {
        text: 'Customers',
        to: { name: 'customers' },
        active: this.isCurrentRoute('customers'),
        class: 'nav-items-default',
        show:
          this._isSalesRepSite(this.site.Name) &&
          this.userSalesRepPermissions.length > 0,
      },
      {
        text: this.site.ShowInventory ? 'Pricing and Inventory Tool' : 'Pricing Tool',
        to: { name: 'export' },
        active: this.isCurrentRoute('export'),
        class: 'nav-items-default',
        show:
          this.userSalesRepPermissions.length > 0 ||
          this.customerPermissions.length > 0,
      },
    ]
  }
}
</script>

<style scoped>
.white-link {
  color: white;
}

.logo {
  height: 40px;
  width: auto;
}

.nav-link.active {
  font-weight: bold;
  color: #fff;
}

@media (max-width: 767px) {
  .nav-link.active {
    background-color: #08528e;
  }
}

.nav-link.active::after {
  background-color: #fff;
}

.nav-link {
  color: whitesmoke !important;
}
</style>
