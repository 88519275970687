import { ActionTree } from 'vuex'
import State, { SearchResult, VisibleModelSearchRequest, VisibleModelSearchRequestWithCustomerNumber } from './types'
import { ResponseArray, RootState } from '../types'
import { Api } from '@/api/Api'
import { isCustomerAdminSite, isCustomerSite, isSalesRepSite } from '@/helpers/sites.helper'

const api = new Api()
const getCatalogId = (rootState: RootState) =>
  !rootState.site.CatalogId ? 5 : rootState.site.CatalogId

export const actions: ActionTree<State, RootState> = {
  performSearch(
    { rootState },
    {
      catalogId,
      keyword,
      page,
      pageSize,
    }: { catalogId?: number; keyword: string; page: number; pageSize: number },
  ): Promise<any> {
    let url: string

    if (isCustomerAdminSite(rootState.role) || isCustomerSite(rootState.site.Name) || isSalesRepSite(rootState.site.Name) || rootState.site.Name === 'cr' || rootState.site.Name === 'editor') {
      url = `/search?keyword=${keyword}&page=${page}&pagesize=${pageSize}&CatalogId=${catalogId ?? getCatalogId(rootState)}`
    } else {
      url = `/catalog/GetSearchResults?keyword=${keyword}&page=${page}&pagesize=${pageSize}`
    }

    return api
      .fetch({
        url,
      })
      .then((response) => {
        const payload: ResponseArray<SearchResult> = response
        return payload
      })
      .catch((error) => {
        throw error
      })
  },
  async searchVisibleModels(
    { commit },
    search: VisibleModelSearchRequest,
  ): Promise<any> {
    commit('visibleModelsLoading')
    const { Keyword, PageSize, Page, Start } = search
    const url =
      '/search/visibleModels?Keyword=' +
      encodeURIComponent(Keyword ?? '') +
      '&PageSize=' +
      PageSize +
      '&Page=' +
      Page +
      '&Start=' +
      Start +
      '&Site=' +
      location.hostname

    return api
      .fetchV2({
        url,
      })
      .then((response) => {
        commit('visibleModelsResults', response)
        return response
      })
      .catch(() => {
        commit('visibleModelsError')
      })
  },
  async searchVisibleModelsWithCustomerNumber(
    { commit },
    search: VisibleModelSearchRequestWithCustomerNumber,
  ): Promise<any> {
    commit('visibleModelsLoading')
    const { Keyword, PageSize, Page, Start, CustomerNumber } = search
    const url =
      '/search/visibleModelsWithCustomerNumber?Keyword=' +
      encodeURIComponent(Keyword ?? '') +
      '&PageSize=' +
      PageSize +
      '&Page=' +
      Page +
      '&Start=' +
      Start +
      '&Site=' +
      location.hostname +
      '&CustomerNumber=' +
      CustomerNumber

    return api
      .fetchV2({
        url,
      })
      .then((response) => {
        commit('visibleModelsResults', response)
        return response
      })
      .catch(() => {
        commit('visibleModelsError')
      })
  },
  async getMaterialGroups(
    { commit },
  ): Promise<any> {
    commit('visibleMaterialGroupLoading')
    const url =
      '/search/MaterialGroup'
    return api
      .fetchV2({
        url,
      })
      .then((response) => {
        commit('visibleMaterialGroupResults', response)
        return response
      })
      .catch(() => {
        commit('visibleMaterialGroupError')
      })
  },
  async getVisibleModelsByPcn({ commit }, pcns: string[]): Promise<any> {
    commit('visibleModelsLoading')
    const url = '/search/visibleModels/pcns'
    const payload = { pcns }
    return api
      .fetchV2({
        url,
        method: 'POST',
        obj: payload,
      })
      .then((response) => {
        return response
      })
      .catch(() => {
        commit('visibleModelsError')
      })
  },
}
