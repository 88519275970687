<template>
  <div>
    <template v-if="!loading">
      <model-series v-if="seriesNumber != ''" :series="series" :fields="fields" :customerNumber="customerNumber">
        <template #seriesHeader="series">
          <slot name="seriesHeader" v-bind="series"></slot>
        </template>
        <template #manufacturingSeriesHeader="manufacturingSeries">
          <slot name="manufacturingSeriesHeader" v-bind="manufacturingSeries"></slot>
        </template>
        <template #productHeader="product">
          <slot name="productHeader" v-bind="product"></slot>
        </template>
        <template #sectionTabHeader="sectionTab">
          <slot name="sectionTabHeader" v-bind="sectionTab"></slot>
        </template>
        <template #sectionTabContentHeader="sectionTab">
          <slot name="sectionTabContentHeader" v-bind="sectionTab"></slot>
        </template>
        <template #sectionTableHeader="sectionTable">
          <slot name="sectionTableHeader" v-bind="sectionTable"></slot>
        </template>
        <template #rowHeader="model">
          <slot name="rowHeader" v-bind="model"></slot>
        </template>
      </model-series>
      <model-category v-if="categoryId != -1" :category="category" :fields="fields">
        <template #categoryHeader="category">
          <slot name="categoryHeader" v-bind="category"></slot>
        </template>
      </model-category>
      <model-productClass v-if="productClassId != -1" :productClass="productClass" :fields="fields">
        <template #productClassHeader="productClass">
          <slot name="productClassHeader" v-bind="productClass"></slot>
        </template>
      </model-productClass>
      <model-manufacturingSeries v-if="manufacturingSeriesId != -1" :manufacturingSeries="manufacturingSeries" :fields="fields" :customerNumber="customerNumber">
        <template #manufacturingSeriesHeader="manufacturingSeries">
          <slot name="manufacturingSeriesHeader" v-bind="manufacturingSeries"></slot>
        </template>
        <template #productHeader="product">
          <slot name="productHeader" v-bind="product"></slot>
        </template>
        <template #sectionTabHeader="sectionTab">
          <slot name="sectionTabHeader" v-bind="sectionTab"></slot>
        </template>
        <template #sectionTabContentHeader="sectionTab">
          <slot name="sectionTabContentHeader" v-bind="sectionTab"></slot>
        </template>
        <template #sectionTableHeader="sectionTable">
          <slot name="sectionTableHeader" v-bind="sectionTable"></slot>
        </template>
        <template #rowHeader="model">
          <slot name="rowHeader" v-bind="model"></slot>
        </template>
      </model-manufacturingSeries>
      <model-product v-if="productId != -1" :product="product" :fields="fields" :customerNumber="customerNumber">
        <template #productHeader="product">
          <slot name="productHeader" v-bind="product"></slot>
        </template>
        <template #sectionTabHeader="sectionTab">
          <slot name="sectionTabHeader" v-bind="sectionTab"></slot>
        </template>
        <template #sectionTabContentHeader="sectionTab">
          <slot name="sectionTabContentHeader" v-bind="sectionTab"></slot>
        </template>
        <template #sectionTableHeader="sectionTable">
          <slot name="sectionTableHeader" v-bind="sectionTable"></slot>
        </template>
        <template #rowHeader="model">
          <slot name="rowHeader" v-bind="model"></slot>
        </template>
      </model-product>
      <model-tab v-else-if="sectionTabId != -1" :fields="fields" :customerNumber="customerNumber">
        <template #sectionTabHeader="sectionTab">
          <slot name="sectionTabHeader" v-bind="sectionTab"></slot>
        </template>
        <template #sectionTabContentHeader="sectionTab">
          <slot name="sectionTabContentHeader" v-bind="sectionTab"></slot>
        </template>
        <template #sectionTableHeader="sectionTable">
          <slot name="sectionTableHeader" v-bind="sectionTable"></slot>
        </template>
        <template #rowHeader="model">
          <slot name="rowHeader" v-bind="model"></slot>
        </template>
      </model-tab>
      <model-table v-if="sectionTable.SectionTableId != -1" :sectionTable="sectionTable" :fields="fields" :customerNumber="customerNumber">
        <template #sectionTableHeader="sectionTable">
          <slot name="sectionTableHeader" v-bind="sectionTable"></slot>
        </template>
        <template #rowHeader="model">
          <slot name="rowHeader" v-bind="model"></slot>
        </template>
      </model-table>
      <model-material-group v-if="materialGroup.MaterialGroupNumber !== ''" :materialGroup="materialGroup" :models="materialGroupModels" :fields="fields" :customerNumber="customerNumber">
        <template #materialGroupHeader="materialGroup">
          <slot name="materialGroupHeader" v-bind="materialGroup"></slot>
        </template>
        <template #rowHeader="model">
          <slot name="rowHeader" v-bind="model"></slot>
        </template>
      </model-material-group>
      <!-- TODO: this may be Model Details -->
      <!-- <model-row v-if="model.PcnCatalogNumber != ''" :model="model" :fields="fields">
        <template #rowHeader="model"><slot name="rowHeader" v-bind="model"></slot></template>
      </model-row> -->
    </template>
    <loading-text v-if="loading" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch } from 'vue-property-decorator'

import LoadingText from '@/components/utility/loading-text.vue'

import MxSite from '@/mixins/site'

import ModelRow from './row.vue'
import ModelTable from './table.vue'
import ModelTab from './tab.vue'
import ModelProduct from './product.vue'
import ModelManufacturingSeries from './manufacturingSeries.vue'
import ModelProductClass from './productClass.vue'
import ModelCategory from './category.vue'
import ModelSeries from './series.vue'
import MaterialGroupComponent from './materialGroup.vue'

import { namespace } from 'vuex-class'
import { blankModel, blankSectionTable, blankProduct, blankManufacturingSeries, blankProductClass, blankCategory, blankSeries } from '@/store/model-viewer/constants'
import { Model, SectionTable, Product, ManufacturingSeries, ProductClass, Category, Series } from '@/store/model-viewer/types'
import { MaterialGroup } from '@/store/search/types'

const nsModelViewer = namespace('model-viewer')
const nsMultiplier = namespace('multiplier')

@Component({
  components: {
    'loading-text': LoadingText,
    'model-table': ModelTable,
    'model-row': ModelRow,
    'model-tab': ModelTab,
    'model-product': ModelProduct,
    'model-manufacturingSeries': ModelManufacturingSeries,
    'model-productClass': ModelProductClass,
    'model-category': ModelCategory,
    'model-series': ModelSeries,
    'model-material-group': MaterialGroupComponent,
  },
})
export default class extends MxSite {
  public model: Model = blankModel
  public sectionTable: SectionTable = blankSectionTable
  public series: Series = blankSeries
  public product: Product = blankProduct
  public manufacturingSeries: ManufacturingSeries = blankManufacturingSeries
  public productClass: ProductClass = blankProductClass
  public category: Category = blankCategory
  public materialGroupModels: Model[] = []

  public loading = false
  @Prop({ default: false })
  public showInventory!: boolean

  @Prop({ default: true })
  public showPrice!: boolean

  @Prop()
  public catalogNumber!: string

  @Prop()
  public seriesNumber!: string

  @Prop()
  public sectionTableId!: number

  @Prop()
  public sectionTabId!: number

  @Prop()
  public productId!: number

  @Prop()
  public manufacturingSeriesId!: number

  @Prop()
  public productClassId!: number

  @Prop()
  public categoryId!: number

  @Prop()
  public selectedItemDisplayName!: string

  @Prop()
  public materialGroup!: MaterialGroup

  @Prop()
  public customerNumber!: string | null

  @nsMultiplier.Getter('getMultiplierPrice')
  public getMultiplierPrice: any

  @nsModelViewer.Action('getModel')
  public getModel: any

  @nsModelViewer.Action('getModelsBySeries')
  public getModelsBySeries: any

  @nsModelViewer.Action('getProductWithModels')
  public getProductWithModels: any

  @nsModelViewer.Action('getManufacturingSeriesWithModels')
  public getManufacturingSeriesWithModels: any

  @nsModelViewer.Action('getModelsByMaterialGroup')
  public getModelsByMaterialGroup: any

  @nsModelViewer.Action('getProductClass')
  public getProductClass: any

  @nsModelViewer.Action('getCategory')
  public getCategory: any

  @nsModelViewer.Action('getMaterialGroup')
  public getMaterialGroup: any

  public mounted() {
    // note: this mainly gets called when you hot reload
    if (this.categoryId !== -1) {
      this.categoryChanged(this.categoryId)
    } else if (this.productClassId !== -1) {
      this.productClassChanged(this.productClassId)
    } else if (this.manufacturingSeriesId !== -1) {
      this.manufacturingSeriesChanged(this.manufacturingSeriesId)
    } else if (this.productId !== -1) {
      this.productChanged(this.productId)
    } else if (this.seriesNumber !== '') {
      this.seriesNumberChanged(this.seriesNumber)
    } else if (this.catalogNumber !== '') {
      this.catalogNumberChanged(this.catalogNumber)
    } else if (this.materialGroup.MaterialGroupNumber !== '') {
      this.materialGroupChanged(this.materialGroup)
    }
  }

  public clearAll() {
    this.model = blankModel
    this.sectionTable = blankSectionTable
    this.product = blankProduct
    this.manufacturingSeries = blankManufacturingSeries
    this.productClass = blankProductClass
    this.category = blankCategory
  }

  @Watch('catalogNumber')
  public catalogNumberChanged(catalogNumber: string) {
    this.loading = true
    this.clearAll()
    if (catalogNumber.trim() === '') {
      this.loading = false
      return
    }
    this.getModel({ catalogNumber }).then(model => {
      if (model) {
        this.sectionTable = { SectionTableId: -2, DisplayName: catalogNumber, Models: [model], OrderBy: 0, Visible: true }
      } else {
        this.sectionTable = { SectionTableId: -2, DisplayName: 'Model Not Found', Models: [], OrderBy: 0, Visible: true }
      }
      this.loading = false
    })
  }

  @Watch('seriesNumber')
  public seriesNumberChanged(seriesNumber: string) {
    this.loading = true
    this.clearAll()
    if (seriesNumber.trim() === '') {
      this.loading = false
      return
    }
    this.getModelsBySeries({ seriesNumber }).then(manufacturingSeries => {
      this.series = { SeriesId: -2, DisplayName: seriesNumber + ' Series', ManufacturingSeries: manufacturingSeries }
      this.loading = false
    })
  }

  @Watch('productId')
  public productChanged(productId: number) {
    if (productId && productId > 0) {
      this.loading = true
      this.clearAll()
      this.getProductWithModels({ productId }).then((product: Product) => {
        this.product = product
        this.loading = false
      })
    }
  }

  @Watch('manufacturingSeriesId')
  public manufacturingSeriesChanged(manufacturingSeriesId: number) {
    if (manufacturingSeriesId && manufacturingSeriesId > 0) {
      this.loading = true
      this.clearAll()
      this.getManufacturingSeriesWithModels({ manufacturingSeriesId }).then((manufacturingSeries: ManufacturingSeries) => {
        this.manufacturingSeries = manufacturingSeries
        this.loading = false
      })
    }
  }

  @Watch('materialGroup')
  public materialGroupChanged(materialGroup: MaterialGroup) {
    this.loading = true
    this.clearAll()
    if (materialGroup.MaterialGroupNumber === '') {
      this.loading = false
      return
    }
    this.getModelsByMaterialGroup({ materialGroup }).then(models => {
      if (models) {
        this.materialGroupModels = models
      }
      this.loading = false
    })
  }

  @Watch('productClassId')
  public productClassChanged(productClassId: number) {
    if (productClassId && productClassId > 0) {
      this.loading = true
      this.clearAll()
      this.getProductClass({ productClassId }).then((productClass: ProductClass) => {
        this.productClass = productClass
        this.productClass.DisplayName = this.selectedItemDisplayName
        this.loading = false
      })
    }
  }

  @Watch('categoryId')
  public categoryChanged(categoryId: number) {
    if (categoryId && categoryId > 0) {
      this.loading = true
      this.clearAll()
      this.getCategory({ categoryId }).then((category: Category) => {
        this.category = category
        this.category.DisplayName = this.selectedItemDisplayName
        this.loading = false
      })
    }
  }

  get fields() {
    // TODO: columns - and overriding columns!
    // - if we get back a sectionTable - then we use that
    // - single Model is different!
    const ret: any[] = [
      {
        label: 'Catalog Number',
        class: '',
        column: 'PcnCatalogNumber',
        key: 'PcnCatalogNumber',
        formatter: undefined,
      },
    ]

    if (this.customerNumber) {
      ret.push({
        label: 'Customer Material Number',
        class: '',
        column: 'CustomerMaterials',
        key: 'CustomerMaterials',
        formatter: (item: any) => {
          const customerMaterialNumber = item.filter(x => x.CustomerNumber === this.customerNumber)
          if (customerMaterialNumber.length < 1) {
            return 'N/A'
          }
          return customerMaterialNumber[0]?.MaterialNumber
        },
      },
      )
    }

    ret.push({
      label: 'Model Number',
      class: '',
      column: 'FingoodTpiMfgMod',
      key: 'FingoodTpiMfgMod',
      formatter: undefined,
    },
    )

    if (this.showPrice) {
      ret.push({
        label: 'List',
        class: '',
        column: 'TotalList',
        key: 'TotalList',
        formatter: (value) => {
          const listPrice = this.formatPrice(value)
          if (listPrice !== '$NaN') {
            return this.formatPrice(value)
          }
          return value
        },
      },
      {
        label: 'Multiplier Pricing',
        class: '',
        column: 'TotalList',
        key: 'MultiplierPricing',
        formatter: (value, key, item) => {
          const multiplierPrice = this.getMultiplierPrice(item, item.ProductHierarchy, item.TotalList)
          if (multiplierPrice > 0) {
            return this.formatPrice(multiplierPrice)
          }
          return 'Call for Pricing'
        },
      })
    }

    if (this.showInventory) {
      ret.push({
        label: 'Inventory',
        class: '',
        column: 'Available',
        key: 'Inventory',
        formatter: undefined,
      })
    }

    return ret
  }

  public formatPrice(price: any): string {
    return `$${Number(price).toFixed(2)}`
  }
}
</script>
<style></style>
